import {
    Button,
    Flex,
    Icon,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
  } from "@chakra-ui/react";
  import React, { useMemo } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from "react-table";
  
  function SearchTable1(props) {

    const columnsData = props.columnsData;
    const tableData = props.tableData;
    const tableType = props.tableType;
    const userEmail = props.userEmail;
    const hideButtons = props?.hideButtons;
  
    const columns = useMemo(() => columnsData, []);
    const data = useMemo(() => tableData, []);
    const history = useNavigate();
  
    const tableInstance = useTable(
      {
        columns,
        data
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      gotoPage,
      pageCount,
      prepareRow,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      setPageSize,
      setGlobalFilter,
      state
    } = tableInstance;
  
    const createPages = (count) => {
      let arrPageCount = [];
  
      for (let i = 1; i <= count; i++) {
        arrPageCount.push(i);
      }
  
      return arrPageCount;
    };
  
    const { pageIndex, pageSize, globalFilter } = state;

    const handleDelete = (id) => {
      
      if(window.confirm("Are you sure you want to delete this item?")){
        const req = axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + '/api/'+tableType+'/remove/'+id+'/'+userEmail).then(res => {
        window.location.reload();  
      }).catch(err => {
          console.log(err);
        });
      }    
    }
  
    return (
      <>
        <Flex
          boxShadow={"5px 5px 10px 10px rgba(0,0,0,0.05)"}
          borderRadius='8px'
          direction="column"
          w="100%"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex justify="space-between" align="center" w="100%" px="22px">
            <Stack
              direction={{ sm: "column", md: "row" }}
              spacing={{ sm: "4px", md: "12px" }}
              align="center"
              me="12px"
              my="24px"
              minW={{ sm: "100px", md: "200px" }}
            >
              <Select
                variant="main"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                color="gray.700"
                size="sm"
                borderRadius="12px"
                maxW="75px"
                cursor="pointer"
              >
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
              </Select>
              <Text fontSize="xs" color="gray.400" fontWeight="normal">
                entries per page
              </Text>
            </Stack>
            <Input

              type="text"
              placeholder="Search..."
              minW="75px"
              maxW="175px"
              fontSize="sm"
              bg='white'
              _focus={{ borderColor: "#000" }}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </Flex>
          <Table {...getTableProps()} variant="simple" color="gray.700" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="0px"
                      key={index}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <Icon
                          w={{ sm: "10px", md: "14px" }}
                          h={{ sm: "10px", md: "14px" }}
                          color={columns.isSorted ? "gray.700" : "gray.400"}
                          float="right"
                          as={
                            column.isSorted
                              ? column.isSortedDesc
                                ? TiArrowSortedDown
                                : TiArrowSortedUp
                              : TiArrowUnsorted
                          }
                        />
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          fontSize={{ sm: "14px" }}
                          key={index}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                    <Td textAlign={'center'}>
                      {tableType=='SMSCampaign'?
                      <>
                        <Button 
                          m={2} 
                          variant='outline'
                          onClick={() => {
                            history('/dashboard/sms-campaign/'+row.original.automationName);
                          }}>View</Button>
                      </>
                      :
                      
                      <>
                      {(!hideButtons) && <>

                          <Button 
                            m={2} 
                            variant='outline'
                            hidden={tableType == 'TBAutomation'?true:false}
                            onClick={() => {
                              history('/dashboard/'+tableType+'/edit/'+row.original.id);
                            }}
                          >
                            Edit
                          </Button>

                          <Button 
                            m={2} 
                            bg='red.400' 
                            _hover={{bg:'red.300'}} 
                            onClick={() => {
                              handleDelete(row.original.id)
                            }} 
                            color='whiteAlpha.800'
                          >
                            Delete
                          </Button>
                        </>
                        }
                      </>
                      }
                      
                    
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify="space-between"
            align="center"
            px="22px"
            w="100%"
            px={{ md: "22px" }}
          >
            <Text
              fontSize="sm"
              color="gray.700"
              fontWeight="normal"
              mb={{ sm: "24px", md: "0px" }}
            >
              Showing {pageSize * pageIndex + 1} to{" "}
              {pageSize * (pageIndex + 1) <= tableData.length
                ? pageSize * (pageIndex + 1)
                : tableData.length}{" "}
              of {tableData.length} entries
            </Text>
            <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
              <Button
                variant="no-effects"
                onClick={() => previousPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="8px"
                bg="#fff"
                border="1px solid lightgray"
                display={
                  pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
                }
                _hover={{
                  bg: "gray.200",
                  opacity: "0.7",
                  borderColor: "gray.700"
                }}
              >
                <Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
              </Button>
              {pageSize === 5 ? (
                <NumberInput
                  max={pageCount - 1}
                  min={1}
                  w="75px"
                  mx="6px"
                  defaultValue="1"
                  onChange={(e) => gotoPage(e)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper onClick={() => nextPage()} />
                    <NumberDecrementStepper onClick={() => previousPage()} />
                  </NumberInputStepper>
                </NumberInput>
              ) : (
                createPages(pageCount).map((pageNumber, index) => {
                  return (
                    <Button
                      variant="no-effects"
                      transition="all .5s ease"
                      onClick={() => gotoPage(pageNumber - 1)}
                      w="40px"
                      h="40px"
                      borderRadius="8px"
                      bg={pageNumber === pageIndex + 1 ? "#000" : "#fff"}
                      border={
                        pageNumber === pageIndex + 1
                          ? "none"
                          : "1px solid lightgray"
                      }
                      _hover={{
                        opacity: "0.7",
                        borderColor: "gray.700"
                      }}
                      key={index}
                    >
                      <Text
                        fontSize="sm"
                        color={pageNumber === pageIndex + 1 ? "#fff" : "gray.800"}
                      >
                        {pageNumber}
                      </Text>
                    </Button>
                  );
                })
              )}
              <Button
                variant="no-effects"
                onClick={() => nextPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="8px"
                bg="#fff"
                border="1px solid lightgray"
                display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
                _hover={{
                  bg: "gray.200",
                  opacity: "0.7",
                  borderColor: "gray.700"
                }}
              >
                <Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
              </Button>
            </Stack>
          </Flex>
        </Flex>
      </>
    );
  }
  
  export default SearchTable1;
  