import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    InputGroup,
    InputLeftAddon,
    Input,
    Checkbox,
    Skeleton,
} from '@chakra-ui/react';

import { FaUserAlt } from "react-icons/fa";
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const DataCap = () => {

    const [email, setEmail] = useState(null);
    const [reviewLink, setReviewLink] = useState(null);
    const [logoURL, setLogoURL] = useState(null);
    const [isDataLoading, setDataLoading] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [dupNumber, setDupNumber] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [confirmCheck, setConfirmCheck] = useState(false);
    const nameRef = useRef();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isValid, setIsValid] = useState(true);

    const handleChange = (event) => {
        const inputValue = event.target.value.replace(/[^\d]/g, ""); // remove non-digits
        setPhoneNumber(inputValue);
        setIsValid(inputValue.length === 10 || inputValue.length === 0); // check if input is a valid US phone number
    };
    const formattedValue = (value) => {
        const regex = /^(\d{0,3})?(\d{0,3})?(\d{0,4})?$/;
        const match = regex.exec(value);
        if (match) {
            const areaCode = match[1] ? `(${match[1]}) ` : "";
            const firstPart = match[2];
            const secondPart = match[3];
            if (value.length <= 3) {
                return value;
            } else if (value.length <= 6) {
                return `${areaCode}${firstPart}`;
            } else {
                return `${areaCode}${firstPart}-${secondPart}`;
            }
        } else {
            return value;
        }
    };

    const getBusiness = () => {
        //get the businesses email address given the accountID
        var path = window.location.pathname;
        var parts = path.split('/');
        const accountID = parts.pop();

        const req = axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/accounts/get/"+accountID).then(res => {
            setEmail(res.data.data.email);
            setReviewLink(res.data.data.reviewLink);
            setLogoURL(res.data.data.logoURL);
            setDataLoaded(true);
        }).catch(err => {
            console.log(err);
        });

    }

    const handleCheckboxChange = event => {
        setIsChecked(event.target.checked);
    };


    const saveMember = () => {
        setConfirmCheck(false);
        if(!isChecked){
            setConfirmCheck(true);
            return
        }
        setDataLoading(true);
        setDupNumber(false);

        const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/;
        if (!phoneNumberRegex.test(phoneNumber)) {
            setDataLoading(false);
            setInvalidPhone(true);
            return;
        }

        if(nameRef.current.value.length ==0){
            alert('Please enter a valid name.');
            setDataLoading(false);
            return;
        }
            const year = new Date().getFullYear();
            const month = new Date().getMonth()+1;
            const day = new Date().getDate();

            const dateString = month + '/' + day + '/' + year;

            axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/contacts/add/"+email, {
                dateAdded: dateString,
                name: nameRef.current.value.trim(),
                phone: phoneNumber,
                notes: "",
            }).then(res => {
                setDataLoading(false);
                window.location = reviewLink;
            }).catch(err => {
                window.location = reviewLink;
                setDataLoading(false);
            });
            setDataLoading(false);
            setInvalidPhone(false);
    }

    useEffect(() =>{
        getBusiness();
    }, []) ;


  return (
    <Box>
        <Flex direction={'column'} p={6}>
            <Flex mb="15vh" direction={'column'}>
                <Skeleton isLoaded={dataLoaded}>
                    <Image margin="0 auto" src={logoURL} alt="logo" maxH="40vh" objectFit="cover" />
                </Skeleton>
                <Box margin="0 auto" textAlign={'center'}>
                    <Text fontSize='1.5em' lineHeight="120%" fontWeight="700" color='#293051'>Thank you for choosing us!</Text>
                    <Text color='#293051' fontWeight="500">Click below to leave a review.</Text>     
                </Box>
                {invalidPhone && 
                    <Box p={4} textAlign='center'>
                    <Text color='red.300'>Uh-oh! Phone number is invalid.</Text>  
                    </Box>
                }
                {confirmCheck && 
                    <Box p={4} textAlign='center'>
                    <Text color='red.300'>Agree to the terms to continue.</Text>  
                    </Box>
                }
                
            </Flex>
            <InputGroup>
                <InputLeftAddon>
                    <FaUserAlt/>
                </InputLeftAddon>
                <Input placeholder='Name' mb={4} ref={nameRef}/>
            </InputGroup>
            <InputGroup>
            <InputLeftAddon children="+1" />
                <Input
                    type="tel"
                    placeholder="(123) 456-7890"
                    value={formattedValue(phoneNumber)}
                    onChange={handleChange}
                    isInvalid={!isValid}
                    maxLength={14} // restrict to 14 characters
                />
            </InputGroup>

            <Checkbox 
                mb={4}
                checked={isChecked}
                onChange={handleCheckboxChange}
            >
                By checking this box I agree to MOLO Boost Terms of Service and Privacy Policy.
            </Checkbox>
                <Button 
                    fontSize='1em' 
                    margin={'0 auto'} 
                    bg='#000' 
                    _hover={{ bg: '#000' }} 
                    fontWeight="700" 
                    color='white'
                    isLoading={isDataLoading}
                    onClick={() => {
                        saveMember()
                    }}
                >
                    Continue to Review
                </Button>
        </Flex>

        <Text color="#85A5EA" fontWeight="600" style={{bottom:0, left:0, position:'fixed'}} pl={2}>MOLO Boost</Text>
    </Box>
  )
}

export default DataCap