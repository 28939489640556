import { 
    Box, 
    Flex,
    Text,
    InputGroup,
    InputRightAddon,
    Button,
    Input,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { useRef, useState } from 'react';
import axios from 'axios';
import { FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseApp } from '../firebase/firebaseConfig'
import logo from '../files/MOLO boost logo.png'


const LoginComponent = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  
  const history = useNavigate();

  const [_, setUser] = useRecoilState(userAtom);

  const [showPassword, setShowPassword] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [err201, seterr201] = useState(false);
  const [errNetwork, seterrNetwork] = useState(false);

  const handleClick = () => {
    // dispatch(loginStart())
      setDataLoading(true);
         
      const auth = getAuth(firebaseApp);
      signInWithEmailAndPassword(auth, emailRef.current.value.trim(), passwordRef.current.value.trim())
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;

          // Set the user state to be the authenticated user's data
          axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/login", {
              email: emailRef.current.value.trim(),
          }).then(res => {
            seterr201(false);
            setUser(res.data.user)
            history('/dashboard/reviews?from=login')
            setDataLoading(false);
          }).catch(err => {
            seterr201(true);
            console.log(err)
            if (err.response && err.response.data.error === 'User not enrolled in either a free trial or subscription') {
              alert("Please enroll in a 7-day free trial.")
              window.location = `https://moloboost.ca/welcome/${err.response.data.accountID}`
            }
            setDataLoading(false);
          });
         
        })
        .catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          console.log(error)


          // Handle the error here - for example, you could set an error state
          seterr201(true);
          setDataLoading(false);
        });
};





  return (
    <Box>
        <Box w={["95%", "30vw"]} margin='0 auto' mt='15vh'>
          <Flex direction='column' color='#000'>
              <img src={logo} style={{width:'50%', margin:' 0 auto'}}/>
              <Text textAlign='center' fontWeight='600' fontSize='1.5rem' >Sign in</Text>
              {err201 && 
                <Box p={4} textAlign='center'>
                  <Text color='red.300'>Something went wrong. Check your inputs and try again.</Text>  
                </Box>
              }
              
              <Box mb={4}>
                <Flex justifyContent='space-between'>
                  <Text>Email</Text>
                </Flex>
                <Input ref={emailRef}/>
              </Box>

              <Box mb={4}>
                <Flex justifyContent='space-between'>
                  <Text>Password</Text>
                  {/* <Text>Forgot username?</Text> */}
                </Flex>
                <InputGroup>
                  <Input 
                    ref={passwordRef}                   
                    type={showPassword?'':'password'} 
                  />
                  <InputRightAddon
                    _hover={{ cursor: 'pointer'}}  
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FaRegEye/>
                  </InputRightAddon>              
                </InputGroup>
              </Box>
              <Box>
                <Button 
                  isLoading={isDataLoading}
                  _hover={{ bg: '#5A7BFF' }} 
                  bg="#5A7BFF" 
                  w='100%' 
                  color='#fff' 
                  fontWeight='500' 
                  fontSize='1.1rem'
                    onClick={() =>handleClick()}
                >
                  SIGN IN
                </Button>
                <Link to='/signup/form' >
                  <Text style={{textDecoration: 'underline'}} textAlign='center' margin='0 auto' w='100%' color='#6da34d'>Don't have an account yet? Sign up</Text>
                </Link>
                <Link to='/reset' >
                  <Text textAlign='center' margin='0 auto' style={{textDecoration: 'underline'}} w='100%' color='#6da34d'>Forgot your password? Reset</Text>
                </Link>
              </Box>
          </Flex>
        </Box>
    </Box>
  )
}

export default LoginComponent
