import React, { useState, useEffect } from 'react'
import {
    Flex,
    Text,
    Divider,
    Avatar,
    Heading
} from '@chakra-ui/react'
import {
    FiHome,
    FiMessageSquare,
    FiUsers,
} from 'react-icons/fi'
// import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { Link } from 'react-router-dom';
import { ImPhone, ImQrcode } from "react-icons/im";
import NavItem from './NavItem'
import { GoZap } from "react-icons/go";
import { FaRobot } from "react-icons/fa";
import { userAtom } from '../recoil/userAtoms';
import { useRecoilValue } from 'recoil';
import { BsPhoneVibrate } from "react-icons/bs";
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import logo from '../files/MOLO boost logo.png'


export default function Sidebar() {
    const [navSize, changeNavSize] = useState("large")
      const user = useRecoilValue(userAtom);
      const setOnboardedFlag = useSetRecoilState(userAtom);

      // const handleOnboardingFlow = () => {
      //   const intro = introJs();
    
      //   // Define the steps of the onboarding flow
      //   intro.setOptions({
      //     steps: [
      //       {
      //         intro: "Welcome to Retainly. \nBefore you start raking in the reviews and phone numbers, let's rake a look around",
      //       },
      //       {
      //         element: '#step1',
      //         intro: 'Use AI to create high converting text templates to send to your list as you grow.',
      //       },
      //       {
      //         element: '#step2',
      //         intro: 'Here is where you set up your text message campaigns. Create a template, choose a schedule, and start sending.',
      //       },
      //       {
      //         element: '#step3',
      //         intro: 'Look through your contacts and manually add opted in memebrs.',
      //       },
      //       {
      //         element: '#step4',
      //         intro: 'View the data of your active text campaigns.',
      //       },
      //       {
      //         element: '#step5',
      //         intro: 'Download you review QR Code so you can put in on display in your business.',
      //       },
      //       {
      //         element: '#step6',
      //         intro: 'Downlaod your Digital Review Card.',
      //       },
      //       {
      //           element: '#step7',
      //           intro: 'Update your billing information and trial/subscription status.',
      //       },
      //       {
      //           intro: "Questions? Contact support at tim@retainlysms.com\nRetainly",
      //       },
      //       // Add more steps as needed
      //     ],
          
           
      //   });
      //   // Event handler when the onboarding flow is completed
      //   intro.oncomplete(() => {
      //     // Send a POST request to update the isOnboarded flag
      //     axios
      //       .post('https://molo-boost-backend-27551f6a06a7.herokuapp.com'+'/api/update-onboarded-flag', {
      //         isOnboarded: true,
      //         accountID: user.accountID,
      //       })
      //       .then(() => {
      //         setOnboardedFlag((prevState) => ({ ...prevState, isOnboarded: true }));
      //       })
      //       .catch((error) => {
      //         console.error(error);
      //         // Handle the error if needed
      //       });
      //   });

      //   // Event handler when the onboarding flow is exited (cancelled)
      //   intro.onexit(() => {
      //     // Send a POST request to update the isOnboarded flag
      //     axios
      //       .post('https://molo-boost-backend-27551f6a06a7.herokuapp.com'+'/api/update-onboarded-flag', {
      //       isOnboarded: true,
      //       accountID: user.accountID,
      //     })
      //     .then(() => {
      //       setOnboardedFlag((prevState) => ({ ...prevState, isOnboarded: true }));
      //     })
      //     .catch((error) => {
      //       console.error(error);
      //       // Handle the error if needed
      //     });
      //   });

    
      //   // Start the onboarding flow
      //   intro.start();
      // };

      useEffect(() => {
      
        // if (!user.isOnboarded) {
        //   handleOnboardingFlow();
        // }
      }, []);
      
      

    return (
        <Flex
            pos='static'
            left="0"
            h='inherit'
            minHeight={'100vh'}
            boxShadow="0 4px 12px 4px rgba(0, 0, 0, 0.15)"
            bg="rgba(0, 0, 0, 0.15)"
            borderRadius={navSize == "small" ? "0px 0px 0px 15px" : "0px 0px 30px 0px"}
            w={navSize == "small" ? "75px" : "200px"}
            flexDir="column"
        >
            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                as="nav"
            >
                {/* <Text margin='0 auto'color="#000" textAlign='center' fontSize="1.5rem" fontWeight="900">MOlO Boost</Text> */}
                <img src={logo} style={{width:'80%', margin:' 0 auto'}}/>
                <Divider display={navSize == "small" ? "none" : "flex"} />

                {/* <Link to={'/dashboard/home'}>
                    <NavItem navSize={navSize} icon={FiHome} title="Dashboard" />
                </Link> */}
                  {/* <Link to={'/dashboard/artificial-intelligence'}>
                      <div id={'step1'}>
                        <NavItem navSize={navSize} icon={FaRobot} title="A.I. Texts"/>
                      </div>
                  </Link> */}
                {/* <Link to={'/dashboard/automations'}>
                    <div id={'step2'}>
                      <NavItem navSize={navSize} icon={GoZap} title="Automations" />
                    </div>
                </Link> */}
                <Link to='/dashboard/contacts'>
                    <div id={'step3'}>
                      <NavItem navSize={navSize} icon={FiUsers} title="Contacts" />
                    </div>
                </Link>
                {/* <Link to='/dashboard/sms-campaign'>
                    <div id={'step4'}>
                      <NavItem navSize={navSize} icon={FiMessageSquare} title="Campaigns" />
                    </div>
                </Link>                 */}
                <Link to='/dashboard/reviews'>
                    <div id={'step5'}>
                      <NavItem navSize={navSize} icon={ImQrcode} title="QR-Code"/>
                    </div>
                </Link>       
                {/* <Link to='/dashboard/review-card'>
                    <div id={'step6'}>
                      <NavItem navSize={navSize} icon={BsPhoneVibrate} title="Review Card"/>
                    </div>
                </Link>    */}
           
            </Flex>

            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                mb={4}
            >
                <Divider display={navSize == "small" ? "none" : "flex"} />
                <Link to='/dashboard/account'>  
                    <div id='step7'>
                      <Flex mt={4} ml={2} align="center">
                          <Avatar size="sm" src="avatar-1.jpg" />
                          <Flex flexDir="column" ml={4} display={navSize == "small" ? "none" : "flex"}>
                              <Heading as="h3" size="sm">{user.firstName}</Heading>
                              <Text color="gray">Admin</Text>
                          </Flex>
                      </Flex>
                    </div>                
                </Link>
            </Flex>
        </Flex>
    )
}